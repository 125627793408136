<template>
  <div class="main-menu">
    <div class="main-overlay">
      <div class="welcome-menu mb-4">
        <div class="text-dark">
          <p class="is-size-5 has-text-weight-bold">Halo, {{ user.username }} <span
              class="has-text-weight-normal is-size-7">({{ getRoleById(selectedFarm.role_id).role_name }})</span></p>
        </div>

        <div class="is-flex report-nav-overlay">
          <b-button type="is-primary" outlined tag="router-link" data-menu-step="2"
                    :to="`/farm/${selectedFarm.farm_id}/history`"
                    v-if="hasCycle">
            {{ $t('report_history') }}
          </b-button>
          <b-button type="is-primary" outlined class="ml-3" @click="reportModal = true" data-menu-step="4"
                    v-if="isPro && hasCycle">
            {{ $t('download_report') }}
          </b-button>
        </div>

        <b-modal
            v-model="reportModal"
            has-modal-card
            :destroy-on-hide="true"
            aria-role="dialog"
            aria-modal>
          <template #default="props">
            <generate-report-form is-modal @close="props.close"></generate-report-form>
          </template>
        </b-modal>
      </div>
    </div>

    <div v-if="!hasCycle" class="has-text-centered my-6">
      <p class="has-text-centered mb-4">Mulai siklus untuk Tambakmu sekarang dengan klik tombol {{ isPro ? 'Atur Siklus' : 'Tebar' }}</p>
      <b-button tag="router-link" :to="`/farm/${selectedFarm.farm_id}/overview`" type="is-primary" outlined v-if="isPro">
        <img src="/img/assets/cycle.png" alt="" style="height: 26px" class="mr-2">
        {{ $t('cycle.set') }} {{ $t('cycle.cycle') }}
      </b-button>
      <b-button @click="stockModal = true" type="is-primary" outlined v-else>
        <img src="/img/assets/stock-harvest.png" alt="" style="height: 26px" class="mr-2">
        {{ $t('stock.stock') }}
      </b-button>
    </div>
    <div v-if="hasCycle" data-menu-step="0" class="mb-4">
      <p class="has-text-weight-bold mb-3">Silahkan masukkan data tambak hari ini.</p>
      <div class="columns is-mobile">
        <div class="column is-3-desktop is-4-tablet is-4-mobile" v-if="canAddWaterQuality">
          <div class="main-menu-item">
            <router-link :to="`/farm/${selectedFarm.farm_id}/water-quality/add`" class="main-menu-item-card">
              <img src="/img/assets/water-quality.png" alt="Water Quality" class="main-menu-item-img">
              <span>{{ $t('wq.wq') }}</span>
            </router-link>
            <div class="main-menu-alert"
                 :class="{
                    'danger': analysis.data ? analysis.data.water_status === 2 : false,
                    'warning': analysis.data ? analysis.data.water_status === 1 : false,
                    'success': analysis.data ? analysis.data.water_status === 0 : false,
                    'grey': analysis.loading === true,
                  }">
              <div class="is-flex is-align-items-center">
                <b-icon :icon="analysis.loading ? 'loading' : 'information-outline'" custom-size="14px"
                        :class="{
                        'icon-loading': analysis.loading,
                      }"/>
                <span class="alert-info" v-if="!analysis.loading && analysis.data">
                  {{ analysis.data.water_desc }}
                </span>
                <span class="alert-info" v-else>Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-3-desktop is-4-tablet is-4-mobile" v-if="canAddAnco && isPro">
          <div class="main-menu-item">
            <router-link :to="`/farm/${selectedFarm.farm_id}/feed-tray/add`" class="main-menu-item-card">
              <img src="/img/assets/feed-tray.png" alt="Feed Tray" class="main-menu-item-img">
              <span>{{ $t('feed_tray.feed_tray') }}</span>
            </router-link>
            <div class="main-menu-alert"
                 :class="{
                    'danger': analysis.data ? analysis.data.anco_status === 2 : false,
                    'warning': analysis.data ? analysis.data.anco_status === 1 : false,
                    'success': analysis.data ?  analysis.data.anco_status === 0: false,
                    'grey': analysis.loading === true,
                  }">
              <div class="is-flex is-align-items-center">
                <b-icon :icon="analysis.loading ? 'loading' : 'information-outline'" custom-size="14px"
                        :class="{
                        'icon-loading': analysis.loading,
                      }"/>
                <span class="alert-info" v-if="!analysis.loading && analysis.data">{{ analysis.data.anco_desc }}</span>
                <span class="alert-info" v-else>Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-3-desktop is-4-tablet is-4-mobile" v-if="canAddWarehouse">
          <div class="main-menu-item">
            <router-link :to="`/farm/${selectedFarm.farm_id}/feed/add`" class="main-menu-item-card">
              <img src="/img/assets/feed.png" alt="Feed" class="main-menu-item-img">
              <span>{{ $t('warehouse.feed') }}</span>
            </router-link>
            <div class="main-menu-alert"
                 :class="{
                    'danger':  analysis.data ? analysis.data.product_status === 2: false,
                    'warning':  analysis.data ? analysis.data.product_status === 1 : false,
                    'success': analysis.data ?  analysis.data.product_status === 0: false,
                    'grey': analysis.loading === true,
                  }">
              <div class="is-flex is-align-items-center">
                <b-icon :icon="analysis.loading ? 'loading' : 'information-outline'" custom-size="14px"
                        :class="{
                        'icon-loading': analysis.loading,
                      }"/>
                <span class="alert-info" v-if="!analysis.loading && analysis.data">
                  {{ analysis.data.product_desc }}</span>
                <span class="alert-info" v-else>Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-3-desktop is-4-tablet is-4-mobile" v-if="canAddWarehouse">
          <div class="main-menu-item">
            <router-link :to="`/farm/${selectedFarm.farm_id}/use-item/add`" class="main-menu-item-card">
              <img src="/img/assets/probiotics.png" alt="Probiotics" class="main-menu-item-img">
              <span>{{ $t('warehouse.probiotics') }}/{{ $t('warehouse.chemical') }}</span>
            </router-link>
            <div class="main-menu-alert"
                 :class="{
                    'danger':  analysis.data ? analysis.data.probiotic_status === 2: false,
                    'warning':  analysis.data ? analysis.data.probiotic_status === 1 : false,
                    'success': analysis.data ?  analysis.data.probiotic_status === 0: false,
                    'grey': analysis.loading === true,
                  }">
              <div class="is-flex is-align-items-center">
                <b-icon :icon="analysis.loading ? 'loading' : 'information-outline'" custom-size="14px"
                        :class="{
                        'icon-loading': analysis.loading,
                      }"/>
                <span class="alert-info" v-if="!analysis.loading && analysis.data">
                  {{ analysis.data.probiotic_desc }}</span>
                <span class="alert-info" v-else>Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-3-desktop is-4-tablet is-4-mobile" v-if="canAddWaterQuality && isPro">
          <div class="main-menu-item">
            <router-link :to="`/farm/${selectedFarm.farm_id}/water-change`" class="main-menu-item-card">
              <img src="/img/assets/water-quality.png" alt="Water Change" class="main-menu-item-img">
              <span>{{ $t('water_change.water_change') }}</span>
            </router-link>
            <div class="main-menu-alert"
                 :class="{
                    'danger':  analysis.data ? analysis.data.water_change_status === 2: false,
                    'warning':  analysis.data ? analysis.data.water_change_status === 1 : false,
                    'success': analysis.data ?  analysis.data.water_change_status === 0: false,
                    'grey': analysis.loading === true,
                  }">
              <div class="is-flex is-align-items-center">
                <b-icon :icon="analysis.loading ? 'loading' : 'information-outline'" custom-size="14px"
                        :class="{
                        'icon-loading': analysis.loading,
                      }"/>
                <span class="alert-info" v-if="!analysis.loading && analysis.data">
                  {{ analysis.data.water_change_desc }}
                </span>
                <span class="alert-info" v-else>Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasCycle" class="mb-4">
      <p class="has-text-weight-bold mb-3">Catat perkembangan tambak udangmu.</p>
      <div class="columns is-mobile">
        <div class="column is-3-desktop is-4-tablet is-4-mobile" v-if="canAddSampling">
          <div class="main-menu-item">
            <router-link :to="`/farm/${selectedFarm.farm_id}/sampling/add`" class="main-menu-item-card">
              <img src="/img/assets/sampling.png" alt="Sampling" class="main-menu-item-img">
              <span>Sampling</span>
            </router-link>
          </div>
        </div>
        <div class="column is-3-desktop is-4-tablet is-4-mobile" v-if="canAddDisease && isPro">
          <div class="main-menu-item">
            <div class="main-menu-item-card" @click="diseaseModal = true">
              <img src="/img/assets/disease.png" alt="Disease" class="main-menu-item-img">
              <span>{{ $t('disease') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-3-desktop is-4-tablet is-4-mobile" v-if="canAddDeath">
          <div class="main-menu-item">
            <div class="main-menu-item-card" @click="deathCountModal = true">
              <img src="/img/assets/death-count.png" alt="Death Count" class="main-menu-item-img">
              <span>{{ $t('death.death') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <p class="has-text-weight-bold mb-3">Kelola Keuangan dan Pengaturan.</p>
      <div class="columns is-mobile">
        <div class="column is-3-desktop is-4-tablet is-4-mobile" data-menu-step="1"
             v-if="canViewWarehouse && hasCycle && isPro">
          <div class="main-menu-item">
            <router-link :to="`/farm/${selectedFarm.farm_id}/warehouse`" class="main-menu-item-card">
              <img src="/img/assets/warehouse.png" alt="Warehouse" class="main-menu-item-img">
              <span>{{ $t('warehouse.warehouse') }}</span>
            </router-link>
          </div>
        </div>
        <div class="column is-3-desktop is-4-tablet is-4-mobile">
          <div class="main-menu-item">
            <router-link :to="`/farm/${selectedFarm.farm_id}/settings`" data-menu-step="1" class="main-menu-item-card">
              <img src="/img/assets/settings.png" alt="Settings" class="main-menu-item-img">
              <span>{{ $t('settings') }}</span>
            </router-link>
          </div>
        </div>
        <div class="column is-3-desktop is-4-tablet is-4-mobile" v-if="!hideFinance && isPro">
          <div class="main-menu-item">
            <router-link :to="`/farm/${selectedFarm.farm_id}/finance`" class="main-menu-item-card">
              <img src="/img/assets/finance.png" alt="Finance" class="main-menu-item-img">
              <span>Finance</span>
            </router-link>
          </div>
        </div>
        <div class="column is-3-desktop is-4-tablet is-4-mobile" v-if="!hideFinance">
          <div class="main-menu-item">
            <router-link :to="`/farm/${selectedFarm.farm_id}/purchase-order`" class="main-menu-item-card">
              <img src="/img/assets/purchase-order.png" alt="Purchase Order" class="main-menu-item-img">
              <span>Purchase Order</span>
            </router-link>
          </div>
        </div>
        <div class="column is-3-desktop is-4-tablet is-4-mobile" v-if="!hideFinance">
          <div class="main-menu-item">
            <a href="https://supply.sgara.id/" target="_blank" class="main-menu-item-card">
              <img src="/img/assets/hub.png" alt="Supply" class="main-menu-item-img">
              <span>Supply</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!--  Stock Modal  -->

    <b-modal
        v-model="stockModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <input-stock-harvest is-modal @close="props.close" type="stock" @submit="handleAddStock" multiple/>
      </template>
    </b-modal>

    <!--  Modal Disease -->

    <b-modal
        v-model="diseaseModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <input-disease is-modal @close="props.close"></input-disease>
      </template>
    </b-modal>

    <!--  Modal Death Count -->

    <b-modal
        v-model="deathCountModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <input-death-count is-modal @close="props.close"></input-death-count>
      </template>
    </b-modal>

    <v-tour name="menuTours" :steps="menuSteps"></v-tour>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "MainMenu",
  components: {
    InputStockHarvest: () => import("@/components/Farm/Sidebar/InputStockHarvest"),
    InputDisease: () => import("@/components/Farm/Sidebar/InputDisease"),
    InputDeathCount: () => import("@/components/Farm/Sidebar/InputDeathCount"),
    GenerateReportForm: () => import("@/components/Farm/Report/GenerateReportForm"),
  },
  data: () => {
    return {
      diseaseModal: false,
      stockModal: false,
      deathCountModal: false,
      reportModal: false,
      menuSteps: [
        {
          target: '[data-menu-step="0"]',  // We're using document.querySelector() under the hood
          header: {
            title: 'Mulai input data sekarang!',
          },
          content: `Kamu bisa mulai input data dengan memilih menu data yang ingin kamu input disini`
        },
        // {
        //   target: '[data-menu-step="1"]',
        //   content: `Untuk melakukan input <b>Pakan dan Probiotik/Kimia</b> kamu perlu menambahkan data Produk di menu Gudang`,
        //   params: {
        //     placement: 'top',
        //   },
        // },
        {
          target: '[data-menu-step="1"]',
          content: `Kamu bisa mengundang user lain untuk bergabung ke tambak mu di menu <b>Pengaturan</b>. Kamu juga bisa mengatur Kolam, Peringatan Kualitas Air, Feeding Rate, dan Feed Index`
        },
        {
          target: '[data-menu-step="2"]',
          content: `Untuk melihat data <b>Kualitas Air, Anco, Pakan, Probiotik/Kimia dan Ganti Air</b> kamu bisa menuju menu Riwayat Laporan`
        },
        {
          target: '[data-menu-step="3"]',
          content: `Untuk melihat data <b>Siklus, Tebar, Sampling, Penyakit, dan Kematian</b> kamu bisa menuju menu Atur Siklus`
        },
        {
          target: '[data-menu-step="4"]',
          content: `Untuk melihat data laporan keseluruhan dalam bentuk PDF kamu bisa menuju menu Download Laporan`
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', [
      'user',
    ]),

    ...mapGetters('farm', [
      'getRoleById',
      'selectedFarm',
      'canAddWaterQuality',
      'canAddAnco',
      'canAddSampling',
      'canAddDisease',
      'canAddDeath',
      'canAddStock',
      'canAddHarvest',
      'canAddCycle',
      'canViewWarehouse',
      'canAddWarehouse',
      'canEditPond',
      'hasCycle',
      'hasStock',
      'isFree',
      'isPro',
      'hideFinance',
    ]),

    ...mapState('farm', [
      'analysis',
      'tours',
    ]),
  },
  created() {
    this.getUnreadMessagesCount()
    this.getStatus()
  },
  mounted() {
    this.$store.commit('UPDATE_NAVBAR')

    if (this.tours.showBackToMenuTours) {
      this.$tours.menuTours.start()
      this.$store.commit('farm/UPDATE_TOURS', {
        tour_name: 'showBackToMenuTours',
        value: false,
      })
    }
  },
  methods: {
    ...mapActions('farm', [
      'getFarmStatus',
    ]),

    ...mapActions('user', [
      'getUnreadMessagesCount',
    ]),

    getStatus() {
      this.getFarmStatus(new Date())
    },

    async handleAddStock() {
      await this.$store.dispatch('farm/getFarmInfo')
      // if (type === 'stock' && isAdd && this.hasStock === 1) {
      //   this.$store.commit('farm/UPDATE_TOURS', {
      //     tour_name: 'showBackToMenuTours',
      //     value: true,
      //   })
      // }
    },
  }
}
</script>
